import { mapFields } from 'vuex-map-fields'
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import * as AutoDialTaskStatus from 'src/constants/power-dialer/task-status'
import moment from 'moment-timezone'
import { get } from 'lodash'
import * as AgentStatus from '../../constants/agent-status'

const DIRECTION = {
  top: 1,
  bottom: 2
}

export default {
  data () {
    return {
      skippedTasks: [],
      countdownInterval: null,
      callInProgress: false,
      countdownStarted: false,
      wrapUp: false,
      reRouteModal: false,
      loadingNext: false,
      sessionNotReady: false,
      skipWrapUp: false,
      verifyAgentOnCall: false
    }
  },

  computed: {
    ...mapState('powerDialer', [
      'powerDialerTasks',
      'inQueueFetchTasks',
      'redialed'
    ]),

    ...mapState([
      'dialer'
    ]),

    ...mapFields('powerDialer', [
      'sessionCallStatuses',
      'sessionPaused',
      'activeTask',
      'ongoingSession',
      'countdownTimer',
      'isSessionRunning',
      'taskToCall',
      'hasActiveTask',
      'redialedTasksCount'
    ]),

    ...mapGetters('contacts', [
      'selectedList'
    ]),

    ...mapGetters('powerDialer', [
      'sessionLoader',
      'sessionSettings'
    ]),

    status () {
      return AutoDialTaskStatus.STATUSES
    },

    statusDisplayText () {
      if (!this.dialer.isReady) {
        return 'Offline'
      }

      switch (this.dialer?.currentStatus) {
        case 'READY':
          // reset call in-progress flag
          this.callInProgress = false

          if (this.timerIsOver || !this.isSessionRunning || this.reRouteModal) {
            return 'Ready'
          }

          if (this.wrapUp) {
            return `Wrap up <span class="text-weight-bold text-grey-7 text-lowercase">${this.countdownTimer >= 0 ? this.countdownTimer : 0}s</span>`
          }

          if (this.sessionPaused) {
            return 'Up Next'
          }

          if (this.countdownTimer > 0) {
            return `Will call in <span class="text-weight-bold text-grey-7 text-lowercase">${this.countdownTimer > 0 ? this.countdownTimer : 0}s</span>`
          }

          if (this.toggleEnd || this.togglePause) {
            return 'Ready'
          }

          return `Dialing...`
        case 'WRAP_UP':
          return `Wrap Up <span class="text-weight-bold text-grey-7 text-lowercase">${this.countdownTimer >= 0 ? this.countdownTimer : 0}s</span>`
        case 'MAKING_CALL':
          return `Dialing...`
        case 'ANSWERING_CALL':
          return 'Answering Call'
        case 'REJECTING_CALL':
          return 'Rejecting Call'
        case 'CALL_CONNECTED':
          return `Connected <span class="text-weight-bold text-grey-7 text-lowercase">${this.dialer.timer}</span>`
        case 'HANGING_UP_CALL':
          return 'Hanging Up Call'
        case 'CALL_DISCONNECTED':
          return 'Call Disconnected'
        case 'OFFLINE':
        default:
          return 'Offline'
      }
    },

    moveDirection () {
      return DIRECTION
    },

    shouldSkip () {
      return this.sessionSettings.skip_outside_daytime_hours === 1
    },

    togglePause: {
      get () {
        return this.sessionCallStatuses.pause
      },
      set (val) {
        this.sessionCallStatuses.pause = val
      }
    },

    toggleEnd: {
      get () {
        return this.sessionCallStatuses.end
      },
      set (val) {
        this.sessionCallStatuses.end = val
      }
    },

    timerIsOver () {
      return this.countdownTimer === -1
    },

    powerDialerSettings () {
      const settings = this.profile.company.power_dialer_settings

      if (settings !== null && settings.open_time && settings.close_time) {
        return settings
      }

      return {
        open_time: '09:00:00',
        close_time: '18:00:00'
      }
    },

    // Should redial if min_redials is set to > 0
    // if has redialed less than min_redials
    // and if the call disposition is not a successful call disposition
    redialRequired () {
      const minRedials = this.sessionSettings?.min_redials

      // min_redials = 0 (redial disabled)
      if (!minRedials || minRedials === 0) {
        console.log('%c redial disabled', 'background-color: green; color: white')
        return false
      }

      // exceeded required min_redials attempts
      if (this.redialedTasksCount[this.activeTask?.id] >= minRedials) {
        return false
      }

      // selected call disposition is a successful call disposition
      const successfulCallDispositionsIds = this.sessionSettings?.successful_call_disposition_ids
      if (successfulCallDispositionsIds?.includes(this.callDisposition)) {
        return false
      }

      // if task is skipped by any reason (timezone, dnc, etc) we should not request a redial
      if (this.isTaskSkipped(this.activeTask?.contact_list_item_id)) {
        return false
      }

      return true
    },

    // If task is being redialed, either by forced redials or manual redial
    isRedialing () {
      return this.redialedTasksCount[this.activeTask?.id] > 0
    }
  },

  methods: {
    ...mapActions('contacts', ['setContact']),

    ...mapActions(['setShowPhone', 'setDialerContact']),

    ...mapActions('powerDialer', [
      'moveContactItems',
      'getSessionTaskByFilter',
      'getContact'
    ]),

    ...mapMutations('powerDialer', [
      'TOGGLE_SESSION_LOADER'
    ]),

    async fetchContact (taskId = null) {
      if (!taskId) {
        this.isSessionRunning = false
        return
      }

      this.TOGGLE_SESSION_LOADER(true)
      await this.getContact({ id: taskId })

      if (!this.isSessionRunning) {
        this.isSessionRunning = true
      }
    },

    async runTask () {
      const timezone = this.taskToCall?.timezone
      // Time without timezone
      const startDay = moment.tz(this.powerDialerSettings.open_time, 'HH:mm:ss', timezone)
      const endDay = moment.tz(this.powerDialerSettings.close_time, 'HH:mm:ss', timezone)
      const contactLocalTime = moment.tz(moment.tz(timezone).format('HH:mm:ss'), 'HH:mm:ss', timezone)

      // If there is no contact TZ then
      // Use the company TZ
      if (this.shouldSkip && !timezone) {
        // Implement: Should skip single task
        this.skipSingleTask(this.taskToCall, 'Task is skipped because timezone has not been set for this contact. Pushed the task to the bottom of the list.')
        // this.moveTask(this.taskToCall, this.moveDirection.bottom)
        return
      }

      // Check if it's outside working hours or not
      if (this.shouldSkip && timezone && !contactLocalTime.isBetween(startDay, endDay)) {
        // Implement: Should skip single task
        this.skipSingleTask(this.taskToCall, 'Task is skipped because it\'s outside day times. Pushed the task to the bottom of the list.')
        // this.moveTask(this.taskToCall, this.moveDirection.bottom)
        return
      }

      // Check if the contact is DNC'ed
      if (this.taskToCall?.is_dnc) {
        this.cancelSingleTask(this.taskToCall, 'Task is removed because the contact is on DNC.')
        return
      }

      this.callInProgress = false

      // only proceed if task has a contact list item id and
      // dialer's status is ready
      if (!this.taskToCall?.contact_list_item_id || !this.dialer.isReady) {
        this.sessionNotReady = true
        return
      }

      this.hasActiveTask = true
      this.skipWrapUp = false

      // check if redial is true (this.redialedTask?.redialed_now) and if agent status is on call
      // then do not continue until agent status is not on call
      if (this.verifyAgentOnCall && this.profile.agent_status === AgentStatus.AGENT_STATUS_ON_CALL) {
        await new Promise(resolve => {
          const checkAgentStatus = setInterval(() => {
            if (this.profile.agent_status !== AgentStatus.AGENT_STATUS_ON_CALL) {
              clearInterval(checkAgentStatus)
              this.verifyAgentOnCall = false
              resolve()
            }
          }, 1000)
        })
      }

      // Fires an event to make a call
      this.$VueEvent.fire('makeCall', {
        currentNumber: this.$options.filters.fixPhone(`power_dialer_task:${this.taskToCall?.contact_list_item_id}`), // we know this already based on the list (Required)
        outboundCampaignId: this.sessionSettings.campaign_id, // this.session.campaignId, // ID of the line that you are calling from (Required)
        contactName: `${this.taskToCall?.first_name} ${this.taskToCall?.last_name}`, // this.contactListItem.name, // the name of the contact that you are calling (Optional but it's best to have it)
        companyName: this.taskToCall?.company_name, // this.contactListItem.company_name, // the name of the company of the contact (Optional but it's best to have it)
        contactId: this.taskToCall?.id // this.contactListItem.contact_id // the ID of the contact (Optional but it's best to have it)
      })

      this.setDialerContact(this.activeTask)
      this.callInProgress = true
    },

    skipSingleTask (autoDialTask, message, skipTask = false) {
      const contactListItemId = get(autoDialTask, 'contact_list_item_id', null)

      if (!contactListItemId) {
        return
      }

      return this.$axios.post(`/api/v2/power-dialer-list-items/${contactListItemId}/skip`)
        .then(res => {
          if (!this.skippedTasks.includes(contactListItemId)) {
            this.skippedTasks.push(contactListItemId)
            const skippedTasks = Array.isArray(this.powerDialerTasks.skipped) ? this.powerDialerTasks.skipped : []
            const tempSet = new Set([...skippedTasks, autoDialTask].map(JSON.stringify)) // Convert each element to JSON to ensure correct comparison
            this.powerDialerTasks.skipped = Array.from(tempSet).map(JSON.parse) // Convert elements back to their original types
          }
          // if (autoDialTask.status !== AutoDialTaskStatus.STATUS_QUEUED) {
          //   // add to bottom of list
          //   // autoDialTask.direction = PowerDialer.DIRECTION_BOTTOM
          //   this.addTaskToList(autoDialTask)
          // }
          // this.skipped_list.push(autoDialTask.id)
          this.$generalNotification(message, 'warning')
          this.onNextTask()
          return Promise.resolve(res)
        }).catch(err => {
          // this.$handleErrors(err.response)
          return Promise.reject(err)
        })
    },

    cancelSingleTask (autoDialTask, message) {
      const contactListItemId = get(autoDialTask, 'contact_list_item_id', null)

      if (!contactListItemId) {
        return
      }

      return this.$axios.post(`/api/v2/power-dialer-list-items/${contactListItemId}/cancel`, { reason: message })
        .then(res => {
          this.$generalNotification(message, 'warning')
          this.onNextTask(true, true)
          return Promise.resolve(res)
        }).catch(err => {
          // this.$handleErrors(err.response)
          return Promise.reject(err)
        })
    },

    redialTask (autoDialTask, redial, forcedRedial = false) {
      const contactListItemId = get(autoDialTask, 'contact_list_item_id', null)

      if (!contactListItemId) {
        return Promise.resolve()
      }

      return this.$axios.post(`/api/v2/power-dialer-list-items/${contactListItemId}/skip`, { redial })
        .then(res => {
          let position = 'bottom'
          if (redial) {
            position = 'top'
            this.taskToCall.contact_list_item_id = res.data.data.id
            this.taskToCall.redialed = res.data.data.redialed
          }

          let message = `Success: contact is at the ${position} of the current list`

          if (forcedRedial) {
            message = `Contact needs to be redialed, adding to the ${position} of current list`
          }

          this.$generalNotification(message)
          return Promise.resolve(res)
        }).catch(err => {
          return Promise.reject(err)
        })
    },

    clearWarmUpCountDown () {
      this.countdownTimer = -1
      clearInterval(this.countdownInterval)

      setTimeout(() => {
        this.countdownStarted = false
      }, 1000)
    },

    skipTask () {
      if (!this.activeTask) {
        return
      }

      if (this.callInProgress) {
        this.$VueEvent.fire('hangupCall')
      }
    },

    addTaskToList () {
      // TODOs: Add task to list
    },

    removeTaskFromList () {
      // TODOs: Remove task from list
    },

    getSkippedAndActiveTasks () {
      const skippedTasks = Array.isArray(this.powerDialerTasks.skipped) ? this.powerDialerTasks.skipped : []
      return [...skippedTasks, this.activeTask]
    },

    updateNumberOfFetchedTasks (taskType, taskCount) {
      // if the current page is the same as the last page, then we keep the total of fetched tasks the same
      if (this.powerDialerTaskFilters[taskType].current_page === this.inQueueFetchTasks.currentPage) {
        this.inQueueFetchTasks.fetchedTasks = taskCount
      }

      // if the current page is greater than the last page, then we increment the total of fetched tasks
      if (this.powerDialerTaskFilters[taskType].current_page > this.inQueueFetchTasks.currentPage) {
        this.inQueueFetchTasks.fetchedTasks += taskCount
      }
    },

    updateCurrentPage (taskType) {
      this.inQueueFetchTasks.currentPage = this.powerDialerTaskFilters[taskType].current_page
    },

    filterNewInQueueTasks (currentList, taskType, updateFetched = false, updatePagination = false) {
      // Get list of processed tasks at this point
      // (Total of skipped tasks in the current PD session + active task)
      const currSkippedAndInProgress = this.getSkippedAndActiveTasks()

      // The new set of IN QUEUE tasks that are retrieved by the API
      const currInQueue = [...currentList]

      if (updateFetched) {
        // Update the number of fetched tasks in the current session
        this.updateNumberOfFetchedTasks(taskType, currInQueue?.length)
      }

      if (updatePagination) {
        // Update the current page in the current session
        this.updateCurrentPage(taskType)
      }

      // We compare the new set of IN QUEUE tasks retrieved by the API according to pagination
      // but discarding the ones have been skipped so we don't list them again
      const newInQueueList = currInQueue.filter(element => !currSkippedAndInProgress.some(item => item.contact_list_item_id === element.contact_list_item_id))

      return newInQueueList
    },

    isTaskSkipped (contactListItemId) {
      return contactListItemId && this.skippedTasks.includes(contactListItemId)
    }
  }
}
